/* styles.css */

/* General Styles */
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #0450b3, #00b4d8);
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-container {
  align-self: center;
  background-color: #1c1c1e;
  border-radius: 15px;
  padding: 20px;
  max-width: 900px;
  width: 90%;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5);
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
}

.color-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.color-input-container label {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

input[type="color"] {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

input[type="color"]:hover {
  transform: scale(1.1);
}

button {
  display: inline-block;
  background: linear-gradient(45deg, #00d4ff, #003882);
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  margin: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

button:hover {
  background: linear-gradient(45deg, #003882, #00b4d8);
  transform: translateY(-3px);
}

.button-exports {
  display: flex;
  justify-content: center;
}
button:disabled {
  background: #555;
  cursor: not-allowed;
}

.theme-container {
  margin-top: 30px;
}

.theme-container h2 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 20px;
}

.theme-category {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #313131;
  border-radius: 8px;
  background-color: #f1f1f1;
}

.theme-category h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

.theme-cards {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.theme-card {
  width: 200px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.theme-card strong {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: inherit;
}

.theme-card span {
  font-size: 0.9rem;
  color: inherit;
}

.theme-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4);
}

/* Responsive Design */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .theme-card {
    padding: 10px;
  }
}
